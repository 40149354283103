<template>
  <b-card
    no-body
    class="p-3"
  >
    <b-row align-h="center">
      <b-col
        sm="12"
        md="7"
      >
        <validation-observer ref="formRef">
          <b-form @submit.prevent>

            <b-form-group
              :label="$t('apps.masterDataIntergration.singular.code')"
              label-for="code"
            >
              <b-form-input
                id="category-code"
                v-model="form.code"
                :placeholder="$t('apps.masterDataIntergration.placeholder.code')"
                :disabled="actions.isPreview"
              />
            </b-form-group>

            <b-form-group
              :label="$t('apps.masterDataActivityIntegration.singular.category')"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="category-name"
                  v-model="form.category_code"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="!actions.isPreview ? $t('apps.masterDataActivityIntegration.placeholder.category') : null"
                  :disabled="actions.isPreview"
                />
                <small class="text-danger">{{ errors[0]}}</small>
              </validation-provider>
            </b-form-group>


            <b-form-group
              :label="$t('apps.masterDataActivityIntegration.singular.activity')"
              label-for="account"
            >
              <validation-provider
                #default="{ errors }"
                name="account_name"
                rules="required"
              >
                <v-select
                  id="account_name"
                  v-model="form.activity_id"
                  :options="LOV.account"
                  :reduce="field => field.id"
                  label="name"
                  :state="errors.length > 0 ? false:null"
                  :disabled="actions.isPreview"
                />
                <small class="text-danger">{{ errors[0]}}</small>
              </validation-provider>
            </b-form-group>

            <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
              <b-button
                class="mb-sm-1 mb-md-0"
                :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'apps-master-data-activity-integration-list'}"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                />
                {{ $t('globalActions.backToList') }}
              </b-button>

              <b-button
                v-if="actions.isPreview"
                class=" mb-sm-1 mb-md-0"
                variant="outline-danger"
                @click="handleDelete"
              >
                <feather-icon
                  icon="Trash2Icon"
                />
                {{ $t('globalActions.delete') }}
              </b-button>

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
              </b-button>

              <b-button
                v-else
                variant="outline-primary"
                @click="changeToEdit"
              >
                <feather-icon
                  icon="EditIcon"
                />
                {{ $t('globalActions.changeToEdit') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
// import i18n from '@/libs/i18n'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  //BFormTextarea,
  BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import useFormResource from '@/comp-functions/useFormResource'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    BForm,
    //BFormTextarea,
    BButton,
    vSelect,
    // BFormText,
    // BFormDatalist,
    ValidationProvider,
    ValidationObserver
  },
  setup () {
    const {
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({ url: 'master/activity-integration', localeContextPath: 'apps.masterDataActivityIntegration.singular.integration', redirectPathName: 'apps-master-data-activity-integration' })

    // LOV (select option)
    const LOV = ref({
      account: [] ,
    })

    const form = ref({
      code: '',
      category_code:'',
      activity_id:''
    })


    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true,
    })

    // will get data to show the preview
    const getDataPreview = async () => {
      const data = await show()
      form.value.code = data[0].code
      form.value.category_code = data[0].category_code
      form.value.activity_id = data[0].activity_id
    }

    // Get account types
    const getAccount = async () => {
      LOV.value.account = await get({ url: 'master/activity-cat'})
    }


    onMounted(() => {
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
      getAccount()
    })

    return {
      required,
      form,
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store,
      numeric,
      LOV
    }
  },
  methods: {
    handleDelete () {
      this.destroy(this.$swal)
    },
    handleSubmit () {
      this.store({
        $swal: this.$swal,
        data: this.form
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
